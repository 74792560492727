import { SalaryScaleFormViewModel } from 'src/types/SalaryScaleFormViewModel';
import { SalaryScaleListItemModel } from 'src/types/SalaryScaleListItemModel';
import { ApiResponseModel } from '../types/ApiResponseModel';
import { DataRequestConfig } from '../types/DataRequestConfig';
import { getApiBaseUrl } from './app.service';
import { executeApiRequest, getAuthToken } from './base.service';
import { getHttpClient, HttpClient } from './httpClient';

export const salaryScaleApiPaths = {
  update: 'salaryScale',
  getListItems: (schoolYear: string) =>
    `salaryScale?calendarYear=${schoolYear}`,
  updateAllListItems: (schoolYear: string, additionalSalary: string) =>
    `/salaryscale/adjustingamount?schoolYear=${schoolYear}&adjustingAmount=${parseInt(
      additionalSalary
    )}`,
  getForm: 'salaryscale/form',
  getMinSalaryListItems: (schoolYear: string) =>
    `salaryscale/getminimumsalaryschedulelist?calendarYear=${schoolYear}`,
};

export const SalaryScaleServiceClient = (
  config?: DataRequestConfig
): HttpClient =>
  getHttpClient(getApiBaseUrl(), getAuthToken() || '', config?.signal);

export const getSalaryScaleListItems = async (
  schoolYear: string,
  config?: DataRequestConfig
): Promise<ApiResponseModel<SalaryScaleListItemModel[]>> => {
  return executeApiRequest<SalaryScaleListItemModel[]>(async () => {
    return await SalaryScaleServiceClient(config).get(
      salaryScaleApiPaths.getListItems(schoolYear)
    );
  });
};

export const getMinSalaryScaleListItems = async (
  schoolYear: string,
  config?: DataRequestConfig
): Promise<ApiResponseModel<SalaryScaleListItemModel[]>> => {
  return executeApiRequest<SalaryScaleListItemModel[]>(async () => {
    return await SalaryScaleServiceClient(config).get(
      salaryScaleApiPaths.getMinSalaryListItems(schoolYear)
    );
  });
};

export const putAllSalaryScaleListItems = async (
  schoolYear: string,
  additionalSalary: string,
  config?: DataRequestConfig
): Promise<ApiResponseModel<SalaryScaleListItemModel[]>> => {
  return executeApiRequest<SalaryScaleListItemModel[]>(async () => {
    return await SalaryScaleServiceClient(config).put(
      salaryScaleApiPaths.updateAllListItems(schoolYear, additionalSalary)
    );
  });
};

export const updateSalaryScale = async (
  body: SalaryScaleListItemModel[],
  config?: DataRequestConfig
): Promise<ApiResponseModel<unknown>> => {
  return executeApiRequest<unknown>(async () => {
    return await SalaryScaleServiceClient(config).put(
      salaryScaleApiPaths.update,
      body
    );
  });
};

export const getSalaryScaleFormViewModel = async (
  config?: DataRequestConfig
): Promise<ApiResponseModel<SalaryScaleFormViewModel>> => {
  return executeApiRequest<SalaryScaleFormViewModel>(async () => {
    return await SalaryScaleServiceClient(config).get(
      salaryScaleApiPaths.getForm
    );
  });
};
