/* eslint-disable max-len */
import { useFormikContext } from 'formik';
import { ReactElement } from 'react';
import { FormRadioListOrientation } from '../../../../types/propTypes/FormRadioListPropTypes';
import { NPSE_OutcomePanelPropTypes } from '../../../../types/propTypes/NPSE_OutcomePanelPropTypes';
import FormDateSelector from '../../../FormInputs/FormDateSelector/FormDateSelector';
import FormDropdown from '../../../FormInputs/FormDropdown/FormDropdown';
import FormRadioList from '../../../FormInputs/FormRadioList/FormRadioList';
import './NPSE_OutcomePanel.css';

const NPSE_OutcomePanel = ({
  label,
  fields,
  entryScoreOptions,
  exitScoreOptions,
  progressOptions,
}: NPSE_OutcomePanelPropTypes): ReactElement => {
  const { values } = useFormikContext();
  const formValues = values as Record<string, unknown>;

  const isEntryDateRequired = (): boolean => {
    return (
      formValues[fields.entryScore] !== undefined &&
      (formValues[fields.entryScore] as string).length > 0
    );
  };

  const isExitDateRequired = (): boolean => {
    return (
      formValues[fields.exitScore] !== undefined &&
      (formValues[fields.exitScore] as string).length > 0
    );
  };

  const isProgressRequired = (): boolean => {
    return (
      formValues[fields.entryScore] !== undefined &&
      (formValues[fields.entryScore] as string).length > 0 &&
      formValues[fields.exitScore] !== undefined &&
      (formValues[fields.exitScore] as string).length > 0
    );
  };

  return (
    <div className="outcome-panel">
      <div>
        <label className="field-label" data-testid="outcome-label">
          {label}
        </label>
      </div>
      <div className="field-row-outcomes top-row">
        <FormDropdown
          displayName="Entry Score"
          field={fields.entryScore}
          options={entryScoreOptions}
        />
        <FormDateSelector
          displayName="Entry Score Date"
          field={fields.entryScoreDate}
          disabled={!isEntryDateRequired()}
          classConfig={{
            labelClasses: isEntryDateRequired() ? 'forced-required-label' : '',
          }}
        />
      </div>
      <div className="field-row-outcomes">
        <FormDropdown
          displayName="Exit Score"
          field={fields.exitScore}
          options={exitScoreOptions}
        />
        <FormDateSelector
          displayName="Exit Score Date"
          field={fields.exitScoreDate}
          disabled={!isExitDateRequired()}
          classConfig={{
            labelClasses: isExitDateRequired() ? 'forced-required-label' : '',
          }}
        />
        <FormRadioList
          displayName="Progress"
          field={fields.progress}
          optionOrientation={FormRadioListOrientation.Horizontal}
          options={progressOptions}
          disabled={!isProgressRequired()}
          classConfig={{
            labelClasses: isProgressRequired() ? 'forced-required-label' : '',
          }}
        />
      </div>
    </div>
  );
};

export default NPSE_OutcomePanel;
