import { VALIDATION_MESSAGE } from '../ValidationMessage';
import yup from './CustomValidation/yup-extended';

export const LEAManagementValidationEditSchema = yup.object().shape({
  leaName: yup
    .string()
    .min(1, VALIDATION_MESSAGE.EXACT_LENGTH(1))
    .max(60, VALIDATION_MESSAGE.AT_MOST_CHARS(60)),
  leaType: yup.string(),
  leaIdentifier: yup
    .string()
    .mustBeNumber()
    .min(4, VALIDATION_MESSAGE.EXACT_NUMBER_LENGTH(4))
    .max(4, VALIDATION_MESSAGE.AT_MOST_CHARS(4))
    .notOneOf(['0000'], VALIDATION_MESSAGE.MUST_NOT_BE_EQUAL_TO('0000')),
  ncesIdentifier: yup
    .string()
    .mustBeNumber()
    .min(7, VALIDATION_MESSAGE.EXACT_NUMBER_LENGTH(7))
    .max(7, VALIDATION_MESSAGE.AT_MOST_CHARS(7)),
  leaOperationalStatus: yup.string(),
  operationalStatusEffectiveDate: yup.date(),
  charterSchoolIndicator: yup.string(),
  reportedFederallyIndicator: yup.string(),
  website: yup.string().max(300, VALIDATION_MESSAGE.AT_MOST_CHARS(300)),
  telephoneNumber: yup
    .string()
    .matches(
      /^[1-9]\d{2}-\d{3}-\d{4}$/,
      VALIDATION_MESSAGE.MUST_BE_IN_PHONE_NUMBER_FORMAT
    ),
  grades: yup.array().min(1, VALIDATION_MESSAGE.MUST_SELECT_AT_LEAST(1)),
  physicalAddressStreetNumberAndName: yup
    .string()
    .max(150, VALIDATION_MESSAGE.AT_MOST_CHARS(150)),
  physicalAptSuiteBuildingFloorNumber: yup
    .string()
    .max(60, VALIDATION_MESSAGE.AT_MOST_CHARS(60)),
  physicalCity: yup.string().max(30, VALIDATION_MESSAGE.AT_MOST_CHARS(30)),
  physicalState: yup.string(),
  physicalZipCode: yup.string().max(17, VALIDATION_MESSAGE.AT_MOST_CHARS(17)),
  physicalCounty: yup.string(),
  mailingAddressStreetNumberAndName: yup
    .string()
    .max(150, VALIDATION_MESSAGE.AT_MOST_CHARS(150)),
  mailingAptSuiteBuildingFloorNumber: yup
    .string()
    .max(60, VALIDATION_MESSAGE.AT_MOST_CHARS(60)),
  mailingCity: yup.string().max(30, VALIDATION_MESSAGE.AT_MOST_CHARS(30)),
  mailingState: yup.string(),
  mailingZipCode: yup.string().max(17, VALIDATION_MESSAGE.AT_MOST_CHARS(17)),
  mailingCounty: yup.string(),
  primaryPositionTitle: yup
    .string()
    .max(45, VALIDATION_MESSAGE.AT_MOST_CHARS(45)),
  primaryFirstName: yup.string().max(75, VALIDATION_MESSAGE.AT_MOST_CHARS(75)),
  primaryLastName: yup.string().max(75, VALIDATION_MESSAGE.AT_MOST_CHARS(75)),
  primaryEmailAddress: yup
    .string()
    .min(7, VALIDATION_MESSAGE.MUST_BE_IN_BETWEEN_RANGE(7, 128))
    .max(128, VALIDATION_MESSAGE.MUST_BE_IN_BETWEEN_RANGE(7, 128))
    .email(VALIDATION_MESSAGE.MUST_BE_IN_VALID_EMAIL_FORMAT),
  primaryTelephoneNumber: yup
    .string()
    .matches(
      /^[1-9]\d{2}-\d{3}-\d{4}$/,
      VALIDATION_MESSAGE.MUST_BE_IN_PHONE_NUMBER_FORMAT
    ),
  secondaryPositionTitle: yup
    .string()
    .max(45, VALIDATION_MESSAGE.AT_MOST_CHARS(45)),
  secondaryFirstName: yup
    .string()
    .max(75, VALIDATION_MESSAGE.AT_MOST_CHARS(75)),
  secondaryLastName: yup.string().max(75, VALIDATION_MESSAGE.AT_MOST_CHARS(75)),
  secondaryEmailAddress: yup
    .string()
    .min(7, VALIDATION_MESSAGE.MUST_BE_IN_BETWEEN_RANGE(7, 128))
    .max(128, VALIDATION_MESSAGE.MUST_BE_IN_BETWEEN_RANGE(7, 128))
    .email(VALIDATION_MESSAGE.MUST_BE_IN_VALID_EMAIL_FORMAT),
  secondaryTelephoneNumber: yup
    .string()
    .matches(
      /^[1-9]\d{2}-\d{3}-\d{4}$/,
      VALIDATION_MESSAGE.MUST_BE_IN_PHONE_NUMBER_FORMAT
    ),
});
